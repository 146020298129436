import React from "react"

import Layout from "../components/layout"

const CareerPage = () => {
  return (
    <Layout>
      <h1>Careers</h1>
      <p>this is career page</p>
    </Layout>
  )
}

export default CareerPage
